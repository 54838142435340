import { Component, input, output, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';

export enum NumberFilterType {
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  EQUAL = 'EQUAL',
}

export type NumberFilterOutput = {
  filterType: NumberFilterType | null;
  value: number | null;
};

// export a function that receives two numbers, a number filter type, and returns a boolean
export function checkNumberFilter(value: number, filterType: NumberFilterType | null, filterValue: number | null): boolean {

  if (filterType === null || filterValue === null) {
    return true;
  }

  switch (filterType) {
    case NumberFilterType.GREATER_THAN:
      return value > filterValue;
    case NumberFilterType.GREATER_THAN_OR_EQUAL:
      return value >= filterValue;
    case NumberFilterType.LESS_THAN:
      return value < filterValue;
    case NumberFilterType.LESS_THAN_OR_EQUAL:
      return value <= filterValue;
    case NumberFilterType.EQUAL:
      return value === filterValue;
  }
}

@Component({
  selector: 'app-number-filter',
  standalone: true,
  imports: [
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule
  ],
  templateUrl: './number-filter.component.html',
  styleUrl: './number-filter.component.scss',
})
export class NumberFilterComponent {
  label = input.required<string>();

  valueControl: FormControl<number | null> = new FormControl(0);

  filterTypeControl: FormControl<NumberFilterType | null>  = new FormControl(NumberFilterType.GREATER_THAN);

  numberFilterTypeEnum = NumberFilterType;

  // a view child to refer to <mat-menu #tableFilter="matMenu">
  @ViewChild('tableFilter') tableFilter!: MatMenu;

  onFilter() {
    this.applied = true;

    const filterType = this.filterTypeControl.value;
    const value = this.valueControl.value;

    // if (filterType === null || value === null) {
    //   return;
    // }

    this.change.emit({
      filterType,
      value,
    });

    this.close();
  }

  //create output
  change = output<NumberFilterOutput>();

  applied: boolean = false;

  clear(){
    this.valueControl.setValue(null);
    this.filterTypeControl.setValue(null);
    this.onFilter();
    this.applied = false;
    this.close();
  }

  close(){
    this.tableFilter._resetAnimation()
  }
}
