import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  exportAsExcelFile(data: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    // Apply number formatting
    this.applyNumberFormatting(worksheet, data);

    // Convert any links in the data to clickable hyperlinks
    // this.addHyperlinksToSheet(worksheet, data);

    const workbook: XLSX.WorkBook = {
      Sheets: { 'data': worksheet },
      SheetNames: ['data']
    };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private applyNumberFormatting(worksheet: XLSX.WorkSheet, data: any[]): void {
    data.forEach((row, rowIndex) => {
      Object.keys(row).forEach((key, colIndex) => {
        let cellValue = row[key];
        const cellRef = XLSX.utils.encode_cell({ r: rowIndex + 1, c: colIndex });
        if (typeof cellValue === 'number') {
          
          if(['ba', 'br'].includes(key) && (cellValue % 1 !== 0))
            cellValue = cellValue.toFixed(1)
          else
            cellValue = Math.round(cellValue)

          worksheet[cellRef] = {
            v: cellValue,
            t: 'n',
            z: (cellValue % 1 === 0) ? '#,##0' : '#,##0.0' // Format integers without decimal, others with one decimal
          };
        } else if (key == 'profit'){
          worksheet[cellRef] = {
            v: 'N\\A',
            t: 's'
          };
        }
      });
    });
  }

  private addHyperlinksToSheet(worksheet: XLSX.WorkSheet, data: any[]): void {
    data.forEach((row, rowIndex) => {
      Object.keys(row).forEach((key, colIndex) => {
        const cellValue = row[key];
        if (cellValue && this.isValidURL(cellValue)) {
          const cellRef = XLSX.utils.encode_cell({ r: rowIndex + 1, c: colIndex });
          worksheet[cellRef] = {
            f: `HYPERLINK("${cellValue}", "${cellValue}")`,
            t: 's'
          };
        }
      });
    });
  }

  private isValidURL(url: string): boolean {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(url);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = URL.createObjectURL(data);
    link.download = fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION;
    link.click();
  }
}

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
