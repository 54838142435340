@if (dataSource.data.length == 0) {
<div class="h-full w-full flex justify-center items-center flex-col p-4 gap-4">
    <h1 class="text-2xl">Revenue Calculator</h1>
    <p *ngIf="!showUploadErrorMsg" class="opacity-80">Calculate potential profit for any property</p>
    <p *ngIf="showUploadErrorMsg" class="opacity-80 text-center">
        <span class="text-red-600">
            <mat-icon class="mat-18" style="vertical-align: -25%;">info</mat-icon>
            Upload Error
        </span>
        <br>
        <br>
        The uploaded file is missing one of the columns: <strong>Location</strong>, <strong>Ba</strong>,
        <strong>Br</strong>.<br>
        <span (click)="downloadSample()" class="link">
            Click here
        </span>
        to download a sample file. Please, try again.
        <br>
        <br>
    </p>
    <app-file-uploader (change)="onFileChange($event)" [accept]="'.xlsx, .csv'" color="primary"></app-file-uploader>
</div>
}
@else{

<div class="p-3 flex flex-col gap-2" style="background-color: #f9fbfd;">
    <div class="w-full flex flex-row gap-2 items-baseline">
        <img src="../../../assets/excel-icon.png" alt="Excel logo" width="25px">
        <h4>{{ fileName }}</h4>
    </div>

    <div class="flex flex-row justify-between p-3 max-md:flex-col gap-3 rounded-md" style="background-color: #edf2fa;">

        <mat-form-field appearance="outline" class="small rounded !-mb-5">
            <mat-label>Filter</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input matInput [(ngModel)]="searchTerm" (keyup)="applyFilter()" #input [disabled]="this.loading">
        </mat-form-field>

        <div class="flex flex-row gap-3 items-center max-md:flex-col max-md:items-stretch">
            <app-file-uploader (change)="onFileChange($event)" [accept]="'.xlsx'" label="Upload"
                [disabled]="this.loading">
            </app-file-uploader>

            <button mat-raised-button [disabled]="this.loading" (click)="openCalculatorSettings()">
                <mat-icon>settings</mat-icon>
                Settings
            </button>

            <button mat-raised-button [disabled]="this.loading" (click)="exportData()">
                <mat-icon>download</mat-icon>
                Download
            </button>

            <app-countdown #countdown></app-countdown>

            <button mat-raised-button color="primary" [disabled]="!extractionKey"
                (click)="this.loading ? stopFetchingEstimate() : startFetchingEstimate() ">
                <mat-icon>{{ this.loading ? 'pause' : 'play_arrow' }}</mat-icon>
                Estimate Profit
            </button>

        </div>

    </div>
</div>

<div class="w-screen overflow-auto">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> No. </th>

            <td mat-cell *matCellDef="let element">
                {{dataSource.data.indexOf(element) + 1}}
            </td>
        </ng-container>

        <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Location"> Location
            </th>
            <td mat-cell *matCellDef="let element" class="whitespace-nowrap"> {{element.location}} </td>
        </ng-container>

        <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Price">                 
                <app-number-filter label="Price / mo" (change)="filterValues.price = $event; applyFilter()"></app-number-filter>
            </th>
            <td mat-cell *matCellDef="let element">
                <input class="input-field text-green-700 !w-32 !text-center" type="text" currencyMask
                    [(ngModel)]="element.price" (ngModelChange)="element.profit = calculateProfit(element)">
            </td>
        </ng-container>

        <ng-container matColumnDef="ba">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Bathrooms"> Bathrooms
            </th>
            <td mat-cell *matCellDef="let element"> {{element.ba}} ba </td>
        </ng-container>

        <ng-container matColumnDef="br">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Bedrooms"> Bedrooms
            </th>
            <td mat-cell *matCellDef="let element"> {{element.br}} bds </td>
        </ng-container>

        <ng-container matColumnDef="airdnaRevenue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Revenue"> 
                <app-number-filter label="AirDna Revenue" (change)="filterValues.revenue = $event; applyFilter()" ></app-number-filter>
            </th>
            <td mat-cell *matCellDef="let element">
                @if (element.loading) {
                <mat-icon class="animate-spin">refresh</mat-icon>
                }
                @else {
                <span [ngClass]="{'text-green-700' : isNumber(element.airdnaRevenue)}">
                    {{ formatAirdnaRevenue(element.airdnaRevenue) }}
                </span>
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="comps_count">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by comps">
                Comps
            </th>
            <td mat-cell *matCellDef="let element">
                @if (element.loading) {
                <mat-icon class="animate-spin">refresh</mat-icon>
                }
                @else {
                <span>
                    {{ element.comps_count ?? '--' }}
                </span>
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="comps_with_pool">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by comps with pool">
                <span class="whitespace-nowrap">
                    Comps With Pool
                </span>
            </th>
            <td mat-cell *matCellDef="let element">
                @if (element.loading) {
                <mat-icon class="animate-spin">refresh</mat-icon>
                }
                @else {
                <span>
                    {{ element.comps_with_pool }}
                </span>
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="comps_revenue_with_pool">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by comps with pool">
                <span class="whitespace-nowrap">
                    Comps revenue with pool
                </span>
            </th>
            <td mat-cell *matCellDef="let element">
                @if (element.loading) {
                <mat-icon class="animate-spin">refresh</mat-icon>
                }
                @else {
                <div class="flex flex-col">
                    @for (comp of element.comps_revenue_with_pool; track comp; let i = $index){
                    <a [href]="comp.link" class="whitespace-nowrap" target="_blank" rel="noopener noreferrer">
                        <span [ngClass]="{'text-green-700' : isNumber(comp.revenue)}">
                            {{ formatAirdnaRevenue(comp.revenue) }}
                        </span>
                        <span class="text-blue-600 ml-2">
                            Comp {{i + 1}}
                            <!-- {{ truncateString(comp.title, 10) }} -->
                        </span>
                    </a>
                    }
                    @empty {
                        <span>--</span>
                    }
                </div>
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="comps_revenue_with_no_pool">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by comps with pool">
                <span class="whitespace-nowrap">
                    Comps revenue with no pool
                </span>
            </th>
            <td mat-cell *matCellDef="let element">
                @if (element.loading) {
                    <mat-icon class="animate-spin">refresh</mat-icon>
                }
                @else {
                <div class="flex flex-col">
                    @for (comp of element.comps_revenue_with_no_pool; track comp; let i = $index){
                    <a [href]="comp.link" class="whitespace-nowrap" target="_blank" rel="noopener noreferrer">
                        <span [ngClass]="{'text-green-700' : isNumber(comp.revenue)}">
                            {{ formatAirdnaRevenue(comp.revenue) }}
                        </span>
                        <span class="text-blue-600 ml-2">
                            Comp {{ i + 1 }}
                            <!-- {{ truncateString(comp.title, 10) }} -->
                        </span>
                    </a>
                    }
                    @empty {
                        <span>--</span>
                    }
                </div>
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="profit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Profit">
                <app-number-filter label="Profit / mo" (change)="filterValues.profit = $event; applyFilter()"></app-number-filter>
            </th>
            <td mat-cell *matCellDef="let element">
                @if (isNumber(element.profit)) {
                <span class="text-green-700">
                    {{ formatAirdnaRevenue(element.profit) }}
                </span>
                }
                @else {
                --
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="link">
            <th mat-header-cell *matHeaderCellDef>Link</th>
            <td mat-cell *matCellDef="let element">
                <a [href]="element.link" target="_blank" rel="noopener noreferrer" (click)="element.clicked = !element.clicked">
                    <mat-icon class="mat-18 text-blue-500" [ngClass]="{'text-purple-800':element.clicked}">link</mat-icon>
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="average_revenue_with_pool">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Average revenue with pool">
                <app-number-filter label="Average revenue with pool" (change)="filterValues.average_revenue_with_pool = $event; applyFilter()"></app-number-filter>
            </th>
            <td mat-cell *matCellDef="let element">
                @if (element.loading) {
                    <mat-icon class="animate-spin">refresh</mat-icon>
                }
                @else {
                    <span [ngClass]="{'text-green-700' : isNumber(element.average_revenue_with_pool)}">
                        {{ formatAirdnaRevenue(element.average_revenue_with_pool) }}
                    </span>
                }
            </td>
        </ng-container>

        <ng-container matColumnDef="average_revenue_with_no_pool">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Average revenue with no pool">
                <app-number-filter label="Average revenue with no pool" (change)="filterValues.average_revenue_with_no_pool = $event; applyFilter()"></app-number-filter>
            </th>
            <td mat-cell *matCellDef="let element">
                @if (element.loading) {
                    <mat-icon class="animate-spin">refresh</mat-icon>
                }
                @else {
                    <span [ngClass]="{'text-green-700' : isNumber(element.average_revenue_with_no_pool)}">
                        {{ formatAirdnaRevenue(element.average_revenue_with_no_pool) }}
                    </span>
                }
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{'highlighted-row': shouldHighlight(row) && loading}"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">
                <p class="text-center p-5">
                    No data matching the filter
                </p>
            </td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons class="mt-4" [disabled]="this.loading"
        aria-label="Select page of periodic elements">
    </mat-paginator>
</div>
}