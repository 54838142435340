<div style="background-color: #0f172a" class="p-4 pb-6 flex justify-between relative text-white">

    <a [routerLink]="['/calculator']">
        <img src="../../../assets/logo.png" alt="" width="100px">
    </a>

    @if (authService.isLoggedIn) {

    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon color="white">menu</mat-icon>
    </button>

    <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item dir="rtl" (click)="router.navigate(['calculator'])">
            <span>Calculator</span>
            <mat-icon class="ml-2">calculate</mat-icon>
        </button>
        <button mat-menu-item dir="rtl" (click)="router.navigate(['manage_user'])">
            <span>My Profile</span>
            <mat-icon class="ml-2">person</mat-icon>
        </button>
        <button mat-menu-item dir="rtl" (click)="logout()">
            <span>Logout</span>
            <mat-icon class="ml-2">logout</mat-icon>
        </button>
        <p class="text-xs text-center opacity-80 mt-1 pt-2 border-t">
            Version 4.2.5
        </p>
    </mat-menu>

    }
</div>