<button [matMenuTriggerFor]="tableFilter" class="flex flex-row gap-2 items-center" (click)="$event.stopPropagation()">
    @if(applied){
    <mat-icon>filter_alt</mat-icon>
    }
    @else{
    <mat-icon class="text-light">filter_list</mat-icon>
    }
    <span class="whitespace-nowrap">
        {{label()}}
    </span>
</button>

<mat-menu #tableFilter="matMenu">

    <div class="flex flex-col gap-4 px-4 pt-4 pb-2" (click)="$event.stopPropagation()">

        <mat-form-field class="no-padding" appearance="outline">
            <mat-label>Filter Type</mat-label>
            <mat-select [formControl]="filterTypeControl">
                <mat-option [value]="numberFilterTypeEnum.GREATER_THAN">Greater than</mat-option>
                <mat-option [value]="numberFilterTypeEnum.GREATER_THAN_OR_EQUAL">Greater than or equal</mat-option>
                <mat-option [value]="numberFilterTypeEnum.LESS_THAN">Less than</mat-option>
                <mat-option [value]="numberFilterTypeEnum.LESS_THAN_OR_EQUAL">Less than or equal</mat-option>
                <mat-option [value]="numberFilterTypeEnum.EQUAL">Equal</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="no-padding" appearance="outline">
            <mat-label>Value</mat-label>
            <input matInput type="number" [formControl]="valueControl" (click)="$event.stopPropagation()">
        </mat-form-field>

        <div class="flex flex-row justify-end gap-2">

            @if(applied){
            <button mat-raised-button (click)="clear()">
                <span class="text-xs">
                    Clear
                </span>
            </button>
            }
            @else {
            <button mat-raised-button (click)="close()">
                <span class="text-xs">
                    Cancel
                </span>
            </button>
            }

            <button mat-raised-button color="primary" (click)="onFilter()">
                <span class="text-xs">
                    Filter
                </span>
            </button>
        </div>

    </div>

</mat-menu>